/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { addMeeting, approveMeeting, attendMeeting, deleteMeeting, getMeetings } from '../api';
import Page from '../../../components/Page';
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import AppointmentTable from './AppointmentTable';
import SnackBarAlert from '../../../components/SnackBarAlert';
import Calendar from './Calendar';
import AppointmentDialog from './AppointmentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  },
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    'font-size': '0.9rem'
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    'font-size': '0.85rem'
  },
  statusText: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    'font-size': '0.85rem'
  },
  appointment: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6
    }
  },
  appointmentList: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  addButton: {
    paddingTop: '6px'
  },
  mainCard: {
    height: '100%'
  }
}));

const ScheduleView = () => {

  const classes = useStyles();

  // Get current Participant from Context
  const participant = useContext(ParticipantContext);
  // Holds list of appointments pulled from backend
  const [appointments, setAppointments] = useState([]);

  // variable to cause re-renders for useEffect
  const [updated, setUpdated] = useState(false);

  // Determines if the alert pop-up is open or not
  const [openAlert, setOpenAlert] = useState(false);
  // Message for the alert pop-up
  const [message, setMessage] = useState('');
  // Severity for the alert pop-up
  const [severity, setSeverity] = useState(``);

  // Selected Appointment
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  // Determines if appointment Dialog is open
  const [openSelectedAppointment, setOpenSelectedAppointment] = useState(false);

  // function to close the selected appointment pop-up
  const handleCloseSelectedAppointment = () => {
    setOpenSelectedAppointment(false);
  };

  // function to open the alert pop-up
  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
  };

  // function close the alert pop-up
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };


  // Pulls list of appointments from backend
  useEffect(() => {
    getMeetings(participant)
      .then((data) => {

        let upcoming = []
        let passed = []
        const today = new Date()

        data.map((appointment) => {

          // Adds Zoom URL to object if it's a zoom type appointment
          if (appointment.type === 'zoom') {
            appointment.zoomURL = `https://nij-zoom.nicholasdwest.com/video?token=${appointment.zoomToken}`;
          }

          // Adds selected variable for interaction between table and calendar
          appointment.selected = false;

          // Adds startDate and endDate variables for Calendar component, and adjusts time if start and end are same
          if (appointment.end_time === appointment.start_time) {
            let tempDate = new Date(appointment.end_time);
            tempDate.setMinutes(tempDate.getMinutes() + 30);
            appointment.startDate = new Date(appointment.start_time);
            appointment.endDate = tempDate;
          } else {
            appointment.startDate = new Date(appointment.start_time);
            appointment.endDate = new Date(appointment.end_time);
          }


          if (appointment.sender === 'case_worker') {
            appointment.sender = 'program specialist'
          }

          if (appointment.startDate > today) {
            upcoming.push(appointment)
          } else {
            passed.push(appointment)
          }

        });

        const sortedUpcoming = upcoming.sort((a, b) => {
          return (a.start_time < b.start_time) ? -1 : ((a.start_time < b.start_time) ? 1 : 0);
        });

        const sortedPassed = passed.sort((a, b) => {
          return (a.start_time > b.start_time) ? -1 : ((a.start_time > b.start_time) ? 1 : 0);
        });

        setAppointments(sortedUpcoming.concat(sortedPassed));
      });
  }, [participant.id, updated]);

  // changes the selected appointment to selected, and sets everything else to unselected
  const handleClick = (event, id) => {
    let appointment = appointments.find((item) => item.id === id);
    setSelectedAppointment(appointment);
    setOpenSelectedAppointment(true);

    let temp = appointments;

    temp.map((appointment) => {
      appointment.selected = id === appointment.id;
    });

    setAppointments(Object.assign([], temp, appointments));
  };

  // deletes a meeting from the backend
  const handleDelete = (event, id) => {
    let meeting = appointments.find((item) => item.id === id);

    deleteMeeting(meeting).then(r => {
      if (r) {
        if (r.status !== 200) {
          handleAlert(`Error: ${r.status} Try again later`, 'error');
        } else {
          handleAlert(`Appointment deleted!`, 'success');
          setAppointments(appointments.filter((item) => item.id !== id));
        }
      } else {
        handleAlert(`No response from server, try again later`, 'error');
      }
    });
  };

  // Creates an appointment in the backend, then refreshes the page if successful
  const handleAddAppointment = (state, startTime, endTime) => {
    let myPromise = new Promise((myResolve, myReject) => {
      addMeeting(participant, 0, state, startTime, endTime)
        .then(r => {
          if (r) {
            if (r.status !== 200) {
              handleAlert(`Error: ${r.status} Try again later`, 'error');
              myReject();
            } else {
              handleAlert(`Appointment added!`, 'success');
              myResolve();
            }
          } else {
            handleAlert(`No response from server, try again later`, 'error');
            myReject();
          }
        });
    });

    myPromise.then(
      function(value) {
        setUpdated(!updated);
      }
    );
  };

  // handling approving or rejecting an appointment
  const handleApproveAppointment = (state, newStatus) => {
    approveMeeting(state, newStatus)
      .then(r => {
        if (r) {
          if (r.status !== 200) {
            handleAlert(`Error: ${r.status} Try again later`, 'error');
          } else {
            handleAlert(`Status changed!`, 'success');
          }
        } else {
          handleAlert(`No response from server, try again later`, 'error');
        }
      });
  };

  const handleAttendAppointment = (state, newStatus) => {
    attendMeeting(state, newStatus)
      .then(r => {
        if (r) {
          if (r.status !== 200) {
            handleAlert(`Error: ${r.status} Try again later`, 'error');
          } else {
            handleAlert(`Status changed!`, 'success');
          }
        } else {
          handleAlert(`No response from server, try again later`, 'error');
        }
      });
  };

  return (
    <Page
      className={classes.root}
      title={'Schedule'}
    >
      <Card className={classes.mainCard}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
            >
              <Calendar appointments={appointments} handleClick={handleClick} />

            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
            >
              <AppointmentTable appointments={appointments} handleDelete={handleDelete} handleClick={handleClick}
                                handleAddAppointment={handleAddAppointment}
                                handleApproveAppointment={handleApproveAppointment}
                                handleAttendAppointment={handleAttendAppointment} />
            </Grid>
          </Grid>


        </CardContent>


      </Card>

      <AppointmentDialog appointment={selectedAppointment} open={openSelectedAppointment}
                         handleCloseDialog={handleCloseSelectedAppointment}
                         handleAttendAppointment={handleAttendAppointment}
                         handleApproveAppointment={handleApproveAppointment} handleDelete={handleDelete} />

      <SnackBarAlert
        open={openAlert}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </Page>
  );
};

export default ScheduleView;
