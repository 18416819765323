import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Card
} from '@mui/material';
import { CardHeader } from '@material-ui/core';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: 'Notification 1',
      body: 'Body 1',
      timestamp: '2023-06-01 10:00:00'
    },
    {
      id: 2,
      title: 'Notification 2',
      body: 'Body 2',
      timestamp: '2023-06-02 12:00:00'
    },
    {
      id: 3,
      title: 'Notification 3',
      body: 'Body 3',
      timestamp: '2023-06-03 15:30:00'
    }
  ]);

  const [newNotificationModalOpen, setNewNotificationModalOpen] = useState(
    false
  );
  const [newNotificationTitle, setNewNotificationTitle] = useState('');
  const [newNotificationBody, setNewNotificationBody] = useState('');

  const openNewNotificationModal = () => {
    setNewNotificationModalOpen(true);
  };

  const closeNewNotificationModal = () => {
    setNewNotificationModalOpen(false);
    setNewNotificationTitle('');
    setNewNotificationBody('');
  };

  const handleSendNotification = () => {
    // Generate a new unique ID for the notification
    const newNotificationId =
      Math.max(...notifications.map(notification => notification.id), 0) + 1;

    // Create a new notification object with current timestamp
    const timestamp = new Date().toLocaleString();
    const newNotification = {
      id: newNotificationId,
      title: newNotificationTitle,
      body: newNotificationBody,
      timestamp: timestamp
    };

    // Add the new notification to the list
    setNotifications(prevNotifications => [
      ...prevNotifications,
      newNotification
    ]);

    closeNewNotificationModal();
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title={'Notifications'}
        action={
          <Button variant="contained" onClick={openNewNotificationModal}>
            Send New Notification
          </Button>
        }
      ></CardHeader>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Body</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map(notification => (
              <TableRow key={notification.id}>
                <TableCell>{notification.title}</TableCell>
                <TableCell>{notification.body}</TableCell>
                <TableCell>{notification.timestamp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={newNotificationModalOpen}
        onClose={closeNewNotificationModal}
      >
        <DialogTitle>Send New Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the title and body of the notification.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={newNotificationTitle}
            onChange={e => setNewNotificationTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Body"
            type="text"
            fullWidth
            value={newNotificationBody}
            onChange={e => setNewNotificationBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewNotificationModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendNotification} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default NotificationsPage;
