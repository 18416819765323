import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Line, Bar, Pie } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import Page from 'src/components/Page';
import { CardHeader } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  chartContainer: {
    marginTop: theme.spacing(2)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const dailyActiveUsersData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Daily Active Users',
        data: [50, 75, 90, 80, 120, 100, 150],
        borderColor: '#36a2eb',
        fill: false
      }
    ]
  };

  const pageVisitsData = {
    labels: ['Home', 'Profile', 'Settings', 'Dashboard'],
    datasets: [
      {
        label: 'Page Visits',
        data: [300, 200, 150, 250],
        backgroundColor: '#ff6384'
      }
    ]
  };

  const sessionData = {
    labels: ['Pending', 'Completed'],
    datasets: [
      {
        data: [5, 10],
        backgroundColor: ['#ffcd56', '#36a2eb']
      }
    ]
  };

  return (
    <Page>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'h5', component: 'span' }}
          style={{ backgroundColor: '#F4F6F8' }}
          title={'GOALS App'}
        ></CardHeader>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Active Users
                </Typography>
                <div className={classes.chartContainer}>
                  <Line data={dailyActiveUsersData} />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Page Visits
                </Typography>
                <div className={classes.chartContainer}>
                  <Bar data={pageVisitsData} />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Session Status
                </Typography>
                <div className={classes.chartContainer}>
                  <Pie data={sessionData} />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'h5', component: 'span' }}
          style={{ backgroundColor: '#F4F6F8' }}
          title={'Health App'}
        ></CardHeader>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Active Users
                </Typography>
                <div className={classes.chartContainer}>
                  <Line data={dailyActiveUsersData} />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Page Visits
                </Typography>
                <div className={classes.chartContainer}>
                  <Bar data={pageVisitsData} />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Session Status
                </Typography>
                <div className={classes.chartContainer}>
                  <Pie data={sessionData} />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Page>
  );
};

export default Dashboard;
