/* eslint-disable */
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  CircleMarker,
  LayersControl,
  Polygon,
  LayerGroup,
  Tooltip
} from 'react-leaflet';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import findObjectCenter from '../../../../utils/findObjectCenter';
import Legend from './Legend';
import './styles.css';


const useStyles = makeStyles((theme) => ({
  root: {}
}));


const ExclusionZoneMap = ({
                            generalExclusionData,
                            personalExclusionData,
                            geoRestrictionProfile,
                            map,
                            setMap,
                            currentLocation
                          }) => {
  const classes = useStyles();

  const defaultCenter = [40.424238309369926, -86.92175786563782];

  return (
    <>
      <MapContainer style={{ height: '65vh', width: '100%' }} zoom={13} center={defaultCenter}
                    whenCreated={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <LayersControl position={'topright'}>
          <LayersControl.Overlay checked={true} name={'General Restriction Zones'}>
            <LayerGroup>
              {generalExclusionData.map((zone) => {
                let zoneColor;
                if (zone.category === 'school') {
                  zoneColor = { color: 'blue', fillOpacity: 0.6, opacity: 0.5 };
                } else if (zone.category === 'playground') {
                  zoneColor = { color: 'green', fillOpacity: 0.6, opacity: 0.5 };
                } else if (zone.category === 'bar') {
                  zoneColor = { color: 'orange', fillOpacity: 0.6, opacity: 0.5 };
                }


                if (zone.geom.type === 'Point') {
                  return (
                    <Circle
                      center={[zone.geom.coordinates[1], zone.geom.coordinates[0]]}
                      pathOptions={zoneColor}
                      radius={10}
                      fillOpacity={0.5}
                      opacity={0.5}
                    >
                      <Tooltip>{zone.locationName}</Tooltip>
                    </Circle>
                  );

                } else if (zone.geom.type === 'Polygon') {
                  return (
                    <Polygon
                      pathOptions={zoneColor}
                      positions={zone.geom.coordinates[0]}
                    >
                      <Tooltip>{zone.locationName}</Tooltip>
                    </Polygon>
                  );
                }
              })}
            </LayerGroup>
          </LayersControl.Overlay>


          <LayersControl.Overlay checked={true} name={'General Restriction Radius'}>
            <LayerGroup>
              {generalExclusionData.map((zone) => {
                if (geoRestrictionProfile) {

                  let center = findObjectCenter(zone);

                  if (zone.category === 'school' && geoRestrictionProfile.schoolRestricted) {
                    return (
                      <Circle
                        center={{ lat: center[1], lng: center[0] }}
                        radius={geoRestrictionProfile.schoolTriggerDistance / 3.28}
                      >
                        <Tooltip>{zone.locationName}</Tooltip>
                      </Circle>
                    );
                  } else if (zone.category === 'playground' && geoRestrictionProfile.playgroundRestricted) {
                    return (
                      <Circle
                        center={{ lat: center[1], lng: center[0] }}
                        radius={geoRestrictionProfile.playgroundTriggerDistance / 3.28}
                      >
                        <Tooltip>{zone.locationName}</Tooltip>

                      </Circle>
                    );
                  } else if (zone.category === 'bar' && geoRestrictionProfile.barRestricted) {
                    return (
                      <Circle
                        center={{ lat: center[1], lng: center[0] }}
                        radius={geoRestrictionProfile.barTriggerDistance / 3.28}
                      >
                        <Tooltip>{zone.locationName}</Tooltip>
                      </Circle>
                    );
                  }
                }
                return (
                  <></>
                );


              })};
            </LayerGroup>
          </LayersControl.Overlay>



          <LayersControl.Overlay checked={true} name={'Personal Restriction Markers'}>
            <LayerGroup>
              {personalExclusionData.map((data) => {
                return (
                  <Marker position={data.geom.coordinates}>
                    <Popup>
                      {data.locationName}
                      <br />
                      Trigger Radius: {Math.round(data.triggerDistance)} ft
                      <br />
                      Comments: {data.comments}
                      <br />
                      {data.geom.coordinates[0]},{data.geom.coordinates[1]}
                      <br />
                      {data.address}
                    </Popup>
                  </Marker>
                );
              })}
            </LayerGroup>

          </LayersControl.Overlay>

          <LayersControl.Overlay checked={true} name={'Personal Restriction Radius'}>
            <LayerGroup>
              {personalExclusionData.map((data) => {
                return (
                  <Circle
                    center={{ lat: data.geom.coordinates[0], lng: data.geom.coordinates[1] }}
                    radius={data.triggerDistance / 3.28}
                  />
                );
              })}

            </LayerGroup>
          </LayersControl.Overlay>

        </LayersControl>

        <Legend map={map} />

        {currentLocation != null ? (
          <CircleMarker
            pathOptions={{ color: 'purple' }}
            center={currentLocation}
          >
            <Tooltip>Participant Latest Location</Tooltip>
          </CircleMarker>
        ) : (
          <>
          </>
        )}

      </MapContainer>

    </>
  );
};

export default ExclusionZoneMap;
