import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Grid,
  Avatar,
  TextField,
  IconButton
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2)
  },
  sidebar: {
    height: 'calc(100vh - 80px)',
    overflowY: 'scroll',
    paddingRight: theme.spacing(1)
  },
  chatWindow: {
    height: 'calc(100vh - 80px)',
    overflowY: 'scroll',
    padding: theme.spacing(2),
    background: '#f1f1f1',
    borderRadius: theme.spacing(1),
    maxHeight: 'calc(100vh - 80px)'
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2)
  },
  messageContent: {
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    maxWidth: '70%',
    background: '#007AFF',
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  otherUserMessageContent: {
    background: '#f2f2f2',
    color: 'black'
  },
  avatar: {
    background: '#007AFF',
    color: 'white',
    marginRight: theme.spacing(1)
  },
  sendMessageBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  messageInput: {
    flexGrow: 1,
    marginRight: theme.spacing(2)
  }
}));

const ChatPage = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Fetch the list of users and messages
    const fetchedUsers = [
      { id: 1, name: 'User 1', lastMessage: 'Hello' },
      { id: 2, name: 'User 2', lastMessage: 'Hi' },
      { id: 3, name: 'User 3', lastMessage: 'Hey' }
    ];

    const fetchedMessages = [
      { userId: 1, content: 'Hi, how are you?' },
      { userId: 1, content: 'Did you watch the game last night?' },
      { userId: 2, content: "Hey, what's up?" },
      { userId: 2, content: 'Are you free this weekend?' },
      { userId: 3, content: 'Hi there!' }
    ];

    setUsers(fetchedUsers);
    setMessages(fetchedMessages);
  }, []);

  const handleUserClick = user => {
    setSelectedUser(user);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newMessageObj = {
        userId: 1, // Assuming the current user has the ID 1
        content: newMessage.trim()
      };

      setMessages([...messages, newMessageObj]);
      setNewMessage('');
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper className={classes.sidebar}>
            <List component="nav" aria-label="users">
              {users.map(user => (
                <ListItem
                  button
                  key={user.id}
                  selected={selectedUser && selectedUser.id === user.id}
                  onClick={() => handleUserClick(user)}
                >
                  <ListItemText
                    primary={user.name}
                    secondary={user.lastMessage}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.chatWindow}>
            {selectedUser ? (
              <div>
                <Typography variant="h6" gutterBottom>
                  Chat with {selectedUser.name}
                </Typography>
                {messages.map((message, index) =>
                  message.userId === selectedUser.id ? (
                    <div
                      className={`${classes.messageContainer} ${
                        message.userId === 1
                          ? classes.messageContainerRight
                          : classes.messageContainerLeft
                      }`}
                      key={index}
                    >
                      {message.userId !== 1 && (
                        <Avatar className={classes.avatar}>
                          {selectedUser.name.charAt(0)}
                        </Avatar>
                      )}
                      <Typography
                        variant="body1"
                        className={`${
                          classes.messageContent
                        } ${message.userId !== 1 &&
                          classes.otherUserMessageContent}`}
                      >
                        {message.content}
                      </Typography>
                    </div>
                  ) : null
                )}
                <div className={classes.sendMessageBox}>
                  <TextField
                    className={classes.messageInput}
                    label="Type a message"
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    variant="outlined"
                  />
                  <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!newMessage.trim()}
                  >
                    <SendIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <Typography variant="subtitle1">
                Select a user to start a chat
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatPage;
