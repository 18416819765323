import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Card
} from '@mui/material';
import { CardHeader } from '@material-ui/core';

const UserListPage = () => {
  const [users, setUsers] = useState([
    { id: 1, email: 'user1@example.com', password: 'password1' },
    { id: 2, email: 'user2@example.com', password: 'password2' },
    { id: 3, email: 'user3@example.com', password: 'password3' }
  ]);

  const [editingUserId, setEditingUserId] = useState(null);
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');

  const openEditPasswordModal = userId => {
    setEditingUserId(userId);
  };

  const closeEditPasswordModal = () => {
    setEditingUserId(null);
  };

  const handleEditPassword = (userId, newPassword) => {
    // Find the user by ID and update the password
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === userId ? { ...user, password: newPassword } : user
      )
    );

    closeEditPasswordModal();
  };

  const openNewUserModal = () => {
    setNewUserModalOpen(true);
  };

  const closeNewUserModal = () => {
    setNewUserModalOpen(false);
    setNewUserEmail('');
    setNewUserPassword('');
  };

  const handleAddUser = () => {
    // Generate a new unique ID for the user
    const newUserId = Math.max(...users.map(user => user.id), 0) + 1;

    // Create a new user object
    const newUser = {
      id: newUserId,
      email: newUserEmail,
      password: newUserPassword
    };

    // Add the new user to the list
    setUsers(prevUsers => [...prevUsers, newUser]);

    closeNewUserModal();
  };

  return (
    <div>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'h5', component: 'span' }}
          style={{ backgroundColor: '#F4F6F8' }}
          action={
            <Button variant="contained" onClick={openNewUserModal}>
              Add New User
            </Button>
          }
          title={'User Management'}
        ></CardHeader>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Password</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.password}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => openEditPasswordModal(user.id)}
                    >
                      Edit Password
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={!!editingUserId} onClose={closeEditPasswordModal}>
          <DialogTitle>Edit Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the new password for the user.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              value={
                users.find(user => user.id === editingUserId)?.password || ''
              }
              onChange={e => handleEditPassword(editingUserId, e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditPasswordModal} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleEditPassword(editingUserId, '')}
              color="primary"
            >
              Remove Password
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={newUserModalOpen} onClose={closeNewUserModal}>
          <DialogTitle>Add New User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the email and password for the new user.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={newUserEmail}
              onChange={e => setNewUserEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={newUserPassword}
              onChange={e => setNewUserPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeNewUserModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddUser} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  );
};

export default UserListPage;
