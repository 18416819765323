/* eslint-disable */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles
} from '@material-ui/core/styles';
import {
  CircleMarker,
  Tooltip
} from 'react-leaflet';
import { ParticipantContext } from '../../../context/ParticipantContext';
import ConvertLocationStr from '../../../utils/ConvertLocationStr';
import theme from 'src/theme';

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100vw',
    height: '100vh',
    overflow: 'unset',
  },
  container: {
    position: 'relative',
  },
  cardContainer: {
    height: '100%',
  },
  participantContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'absolute',
    width: 'calc(100% - 300px)',
  },
  participantList: {
    height: 240,
    width: 300,
    position: 'absolute',
    top: '20px',
    left: '20px',
    'z-index': theme.zIndex.drawer - 1,
  },
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  leafletTooltip: {
    padding: '0px 4px 0px 4px !important',
  },
  selectParticipantCircle: {
    'z-index': 201,
  }
}));

const CircleMarkerGroup = ({ className, participantData, toggleCircleMarker, selectParticipant, ...rest }) => {
  const classes = useStyles();
  const participants = participantData;
  const participantContext = useContext(ParticipantContext);

  const colorBlue = { color: theme.palette.dot.blue, };

  var circleMarkerGroup = [];
  if (participants != undefined) {
    participants.map((participant) => {
      if (participant.latestLocation != undefined) {
        var latestLocation = ConvertLocationStr(participant.latestLocation);
        // var latestLocation = participant.latestLocation;
        if (participant.id != participantContext.id) {
          const keyLabel = `circlemarker-${participant.id}`;
          circleMarkerGroup.push(
            <CircleMarker
              center={latestLocation}
              pathOptions={{ color: 'purple' }}
              pane={"overlayPane"}
              radius={10}
              opacity={1}
              key={keyLabel}
              eventHandlers={{
                click: () => {selectParticipant(participant.id)},
              }}
            >
              <Tooltip
                direction='bottom'
                opacity={1}
                permanent
                className={classes.leafletTooltip}
                offset={[0, 7]}
                pane={"tooltipPane"}
              >
                {participant.name}<br />{participant.gpsUploadTime == "" ? <>Last Updated:{ }</> : <>Last Updated: {new Date(participant.gpsUploadTime).toLocaleString() }</>}
              </Tooltip>
            </CircleMarker>
          );
        }
      }
    });
  }

  return (
    <div>
      {circleMarkerGroup}
    </div>
  );
}

CircleMarkerGroup.propTypes = {
  className: PropTypes.string
};

export default CircleMarkerGroup;
