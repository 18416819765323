/* eslint-disable */
import {
  Card,
  CardHeader, makeStyles,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel, Typography,
  Grid, Paper, Input,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {useContext, useEffect, useState} from "react";
import NotificationList from "../../../home/Overview/ParticipantList/NotificationList";
import {NotificationContext} from "../../../../context/NotificationContext";
import {ParticipantContext} from "../../../../context/ParticipantContext";
import PropTypes from "prop-types";
import ParticipantList from "../../../home/Overview/ParticipantList";

// Portions of this sourced from https://github.com/TeamWertarbyte/material-ui-search-bar/blob/master/src/components/SearchBar/SearchBar.js

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: 'GhostWhite',
      cursor: 'pointer',
    },
  },
  paper: {
    height: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    margin: "auto 16px",
    width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
  },
}));


const ParticipantListTable = ({ data, sortData, taskRates, userScores, userLevels, violationCounts }) => {
  let screenWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)

  // variable to set max number of rows on the table, initialized to 8
  const [limit, setLimit] = useState((screenWidth > 1536 ? 12 : 8 ));
  // variable to set the current table page, initialized to 0
  const [page, setPage] = useState(0);

  const [sortingBy, setSortingBy] = useState(null);
  const [order, setOrder] = useState('asc');
  const [indexOrder, setIndexOrder] = useState(defaultSort());
  const [searchIndexOrder, setSearchIndexOrder] = useState(defaultSort());
  const [search, setSearch] = useState(false)

  const notificationContext = useContext(NotificationContext);
  const participantContext = useContext(ParticipantContext);

  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false)

  const classes = useStyles();

  function trimParticipantId(participantId) {
      // return participantId.substring(2,14)
    console.log(participantId.toString())
    
    if(participantId.length > 1) {
        return participantId.substring(2,13)
    } else {
      return participantId
    }

    return participantId
      
  }

  function defaultSort ()
  {
    let tmpOrder = []
    data.map((data, i) => {
      tmpOrder.push(i)
    })
    return tmpOrder
  }

  const SortById = () => {
    setSortingBy('id');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = sortData.sort((a, b) => {
        return (a.id > b.id) ? -1 : ((a.id < b.id) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = sortData.sort((a, b) => {
        return (a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      data.map((data, j) => {
        if (data.id === sort.id)
        {
          tmpOrder.push(j)
        }
      })
    })
    setIndexOrder(tmpOrder)
  };

  const SortByName = () => {
    setSortingBy('name');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = sortData.sort((a, b) => {
        //switch names to last name first unless only one name is included and ignore case
        const nameA = a.name.includes(" ") ? a.name.substring(a.name.lastIndexOf(" ") + 1).toLowerCase() + " " + a.name.substring(0, a.name.lastIndexOf(" ")).toLowerCase() : a.name.toLowerCase()
        const nameB = b.name.includes(" ") ? b.name.substring(b.name.lastIndexOf(" ") + 1).toLowerCase() + " " + b.name.substring(0, b.name.lastIndexOf(" ")).toLowerCase() : b.name.toLowerCase()

        return (nameA > nameB) ? -1 : ((nameA < nameB) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = sortData.sort((a, b) => {
        //switch names to last name first unless only one name is included and ignore case
        const nameA = a.name.includes(" ") ? a.name.substring(a.name.lastIndexOf(" ") + 1).toLowerCase() + " " + a.name.substring(0, a.name.lastIndexOf(" ")).toLowerCase() : a.name.toLowerCase()
        const nameB = b.name.includes(" ") ? b.name.substring(b.name.lastIndexOf(" ") + 1).toLowerCase() + " " + b.name.substring(0, b.name.lastIndexOf(" ")).toLowerCase() : b.name.toLowerCase()

        return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      data.map((data, j) => {
        if (data.id === sort.id)
        {
          tmpOrder.push(j)
        }
      })
    })
    setIndexOrder(tmpOrder)
  };

  //all sorts sort by ascending id order in the case of equal elements
  const SortByTaskRate = () => {
    setSortingBy('taskRate');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = taskRates.sort((a, b) => {
        return (a.rate > b.rate) || (a.rate === b.rate && a.index < b.index) ? -1 : ((a.rate < b.rate) || (a.rate === b.rate && a.index > b.index) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = taskRates.sort((a, b) => {
        return (a.rate < b.rate) || (a.rate === b.rate && a.index < b.index) ? -1 : ((a.rate > b.rate) || (a.rate === b.rate && a.index > b.index) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      tmpOrder.push(sort.index)
    })
    setIndexOrder(tmpOrder)
  };

  const SortByScore = () => {
    setSortingBy('score');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = userScores.sort((a, b) => {
        return (a.score > b.score) || (a.score === b.score && a.index < b.index) ? -1 : ((a.score < b.score) || (a.score === b.score && a.index > b.index) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = userScores.sort((a, b) => {
        return (a.score < b.score) || (a.score === b.score && a.index < b.index) ? -1 : ((a.score > b.score) || (a.score === b.score && a.index > b.index) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      tmpOrder.push(sort.index)
    })
    setIndexOrder(tmpOrder)
  };

  const SortByLevel = () => {
    setSortingBy('level');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = userLevels.sort((a, b) => {
        return (a.level > b.level) || (a.level === b.level && a.index < b.index) ? -1 : ((a.level < b.level) || (a.level === b.level && a.index > b.index) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = userLevels.sort((a, b) => {
        return (a.level < b.level) || (a.level === b.level && a.index < b.index) ? -1 : ((a.level > b.level) || (a.level === b.level && a.index > b.index) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      tmpOrder.push(sort.index)
    })
    setIndexOrder(tmpOrder)
  };

  const SortByViolation = () => {
    setSortingBy('violation');
    let sorted;
    if (order === 'asc') {
      setOrder('desc');
      sorted = violationCounts.sort((a, b) => {
        return (a.count > b.count) || (a.count === b.count && a.index < b.index) ? -1 : ((a.count < b.count) || (a.count === b.count && a.index > b.index) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = violationCounts.sort((a, b) => {
        return (a.count < b.count) || (a.count === b.count && a.index < b.index) ? -1 : ((a.count > b.count) || (a.count === b.count && a.index > b.index) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      tmpOrder.push(sort.index)
    })
    setIndexOrder(tmpOrder)
  };

  const SortByNotification = () => {
    setSortingBy('Notification');
    let sorted;
    let tmpData = [];
    data.map((data, i) => {
        tmpData.push({
          notifLength: notificationContext.notificationByParticipant.get(data.id) ? notificationContext.notificationByParticipant.get(data.id).length : 0,
          index: i
        })
    })

    if (order === 'asc') {
      setOrder('desc');
      sorted = tmpData.sort((a, b) => {
        return (a.notifLength > b.notifLength) || (a.notifLength === b.notifLength && a.index < b.index) ? -1 : ((a.notifLength < b.notifLength) || (a.notifLength === b.notifLength && a.index > b.index) ? 1 : 0);
      });
    } else {
      setOrder('asc');
      sorted = tmpData.sort((a, b) => {
        return (a.notifLength < b.notifLength) || (a.notifLength === b.notifLength && a.index < b.index) ? -1 : ((a.notifLength > b.notifLength) || (a.notifLength === b.notifLength && a.index > b.index) ? 1 : 0);
      });
    }
    let tmpOrder = []
    sorted.map((sort) => {
      tmpOrder.push(sort.index)
    })
    setIndexOrder(tmpOrder)
  };

  // changes the limit
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // changes the page
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleParticipantSelection = (participantId) => {
    if (participantContext.list != undefined) {
      participantContext.list.map((participant) => {
        if (participant.id == participantId) {
          participantContext.setName(participant.name);
          localStorage.setItem('participantName', JSON.stringify(participant.name))
          participantContext.setId(participant.id);
          localStorage.setItem('participantId', JSON.stringify(participant.id))
          participantContext.setLatestLocation(participant.latestLocation);
          localStorage.setItem('participantLatestLocation', JSON.stringify(participant.latestLocation))
          if (participant.address) {
            participantContext.setStreet(participant.address.street);
            participantContext.setCity(participant.address.city);
            participantContext.setState(participant.address.state);
            localStorage.setItem('participantStreet', JSON.stringify(participant.address.street))
            localStorage.setItem('participantCity', JSON.stringify(participant.address.city))
            localStorage.setItem('participantState', JSON.stringify(participant.address.state))
          } else {
            participantContext.setStreet("");
            participantContext.setCity("");
            participantContext.setState("");
            localStorage.setItem('participantStreet', JSON.stringify(''))
            localStorage.setItem('participantCity', JSON.stringify(''))
            localStorage.setItem('participantState', JSON.stringify(''))
          }
        }
      });
    }
  }

  const handleSearchChange = (event) => {
    let tempInput = event.target.value.toLowerCase();
    setInput(event.target.value);
    if (event.target.value === '') {
      setSearch(false)
      return;
    }
    let tmpOrder = [];
    indexOrder.map((index) => {
      if (data[index].name.toLowerCase().includes(tempInput))
      {
        tmpOrder.push(index)
      }
    })
    setSearch(true)
    setSearchIndexOrder(tmpOrder)
  };

  return (
    <Card >
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title={'Participant List'}
        action={
          <>
            <Grid container>
              <Grid item>
                {open ? (
                  <Paper className={classes.paper}>
                    <div className={classes.searchContainer}>
                      <Input
                        value={input}
                        onChange={handleSearchChange}
                        disableUnderline={true}
                        placeholder={'Search'}
                        autoFocus={true}
                      >
                      </Input>
                    </div>
                    <IconButton
                      onClick={(event) => {
                        event.target.value = ''
                        handleSearchChange(event)
                        setOpen(false)
                      }}
                    >
                      <CancelIcon/>
                    </IconButton>
                  </Paper>
                ) : (
                  <IconButton
                    onClick={() => setOpen(true)}
                  >
                    <SearchIcon/>
                  </IconButton>

                )}
              </Grid>
            </Grid>

          </>

        }
      />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableCell>

              <TableSortLabel
                active={sortingBy === 'id'}
                direction={sortingBy === 'id' ? (order) : 'asc'}
                onClick={() => {
                  SortById();
                }}
              >
                Participant ID
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'name'}
                direction={sortingBy === 'name' ? (order) : 'asc'}
                onClick={() => {
                  SortByName();
                }}
              >
                Participant Name
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'taskRate'}
                direction={sortingBy === 'taskRate' ? (order) : 'asc'}
                onClick={() => {
                  SortByTaskRate();
                }}
              >
                Task Completion Rate
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'score'}
                direction={sortingBy === 'score' ? (order) : 'asc'}
                onClick={() => {
                  SortByScore();
                }}
              >
                Points
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'level'}
                direction={sortingBy === 'level' ? (order) : 'asc'}
                onClick={() => {
                  SortByLevel();
                }}
              >
                Level
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'violation'}
                direction={sortingBy === 'violation' ? (order) : 'asc'}
                onClick={() => {
                  SortByViolation();
                }}
              >
                # of Violations
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={sortingBy === 'notification'}
                direction={sortingBy === 'notification' ? (order) : 'asc'}
                onClick={() => {
                  SortByNotification();
                }}
              >
                Notifications
              </TableSortLabel>
            </TableCell>

          </TableHead>

          {search ? (indexOrder.length < page * limit ? setPage(0) : "")
          :(searchIndexOrder.length < page * limit ? setPage(0) : "")}

          {indexOrder.filter(index => search ? searchIndexOrder.includes(index) : true).slice(page * limit, page * limit + limit).map((index) => {
            return (
              <TableRow className={classes.tableRow} button onClick={() => handleParticipantSelection(data[index].id)}>
                <TableCell>
                  {data[index].id == participantContext.id ?
                    <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{trimParticipantId(`${data[index].id}`)}</Typography>
                    :
                    <Typography>{trimParticipantId(`${data[index].id}`)}</Typography>
                  }
                </TableCell>

                <TableCell>
                  {data[index].id == participantContext.id ?
                    <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${data[index].name}`}</Typography>
                    :
                    <Typography>{`${data[index].name}`}</Typography>
                  }
                </TableCell>

                <TableCell>
                  {taskRates.map((taskRate) => {
                    if (taskRate.index === index)
                    {
                      return (data[index].id == participantContext.id ?
                          <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${taskRate.rate}`}</Typography>
                          :
                          <Typography>{`${taskRate.rate}`}</Typography>
                      )
                    }
                  })}
                </TableCell>

                <TableCell>
                  {userScores.map((userScores) => {
                    if (userScores.index === index)
                    {
                      return (data[index].id == participantContext.id ?
                          <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${userScores.score}`}</Typography>
                          :
                          <Typography>{`${userScores.score}`}</Typography>
                      )
                    }
                  })}
                </TableCell>

                <TableCell>
                  {userLevels.map((userLevel) => {
                    if (userLevel.index === index)
                    {
                      return (data[index].id == participantContext.id ?
                          <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${userLevel.level}`}</Typography>
                          :
                          <Typography>{`${userLevel.level}`}</Typography>
                      )
                    }
                  })}
                </TableCell>

                <TableCell>
                  {violationCounts.map((count) => {
                    if (count.index === index)
                    {
                      return (data[index].id == participantContext.id ?
                          <Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${count.count}`}</Typography>
                          :
                          <Typography>{`${count.count}`}</Typography>
                      )
                    }
                  })}
                </TableCell>

                <TableCell>
                  {data[index].id == participantContext.id ?
                    <NotificationList
                      participantId={data[index].id}
                      selected='true'
                    />
                    :
                    <NotificationList
                      participantId={data[index].id}
                      selected='false'
                    />
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={search ? searchIndexOrder.length : indexOrder.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 8, 12, 25, 50]}
      />
    </Card>
  )
}

ParticipantList.propTypes = {
  className: PropTypes.string
};

export default ParticipantListTable
