/* eslint-disable */

import {
  Box,
  Button, Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  makeStyles, TextField,
  Typography
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ParticipantContext } from '../../../../context/ParticipantContext';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { addPersonalExclusion, convertAddressToLatLong } from '../../api';
import { CircularProgress } from '@mui/material';

const typeCategories = [
  {
    value: 'address',
    label: 'Address'
  },
  {
    value: 'longlat',
    label: 'Longitude and Latitude'
  }
];

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingTop: '0px'
  }
}));

const AddPersonalRestrictionDialog = ({ open, handleCloseDialog, handleAlert }) => {

  const classes = useStyles();

  const participant = useContext(ParticipantContext);

  const [state, setState] = useState({
    location: '',
    category: '',
    triggerRadius: 0,
    comments: '',
    type: 'address'
  });

  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (state.location === '' || state.triggerRadius <= 0 || state.comments === '' || state.category === '') {
      return;
    } else if (state.type === 'address' && address === '') {
      return;
    }

    if (state.type === 'address') {
      convertAddressToLatLong(address)
        .then((r) => {
          if (r) {
            if (r.status === 200) {
              let localLatitude = r.data.data[0].latitude;
              let localLongitude = r.data.data[0].longitude;
              addPersonalExclusion(participant, state, localLatitude, localLongitude, address)
                .then((r) => {
                  if (r) {
                    if (r.status === 200) {
                      cancelDialog();
                      handleAlert('Successfully added new personal restriction', 'success');
                    } else {
                      cancelDialog();
                      handleAlert(`Error: ${r.status} Try again later`, 'error');
                    }
                  } else {
                    cancelDialog();
                    handleAlert(`No response from server, try again later`, 'error');
                  }
                });
            } else {
              cancelDialog();
              handleAlert(`No response from server, try again later`, 'error');
            }
          }
        });
    } else {
      addPersonalExclusion(participant, state, latitude, longitude)
        .then((r) => {
          if (r) {
            if (r.status === 200) {
              cancelDialog();
              handleAlert('Successfully added new personal restriction', 'success');
            } else {
              cancelDialog();
              handleAlert(`Error: ${r.status} Try again later`, 'error');
            }
          } else {
            cancelDialog();
            handleAlert(`No response from server, try again later`, 'error');
          }
        });
    }


  };

  const cancelDialog = () => {
    setState({
      location: '',
      category: '',
      triggerRadius: 0,
      comments: '',
      type: 'address'
    });
    setAddress('');
    setLatitude(0);
    setLongitude(0);
    handleCloseDialog();
  };

  return (
    <div>
      <form onSubmit={handleSave}>
        <Dialog open={open} onClose={cancelDialog} maxWidth={'xs'}>

          <DialogTitle>
            <Typography>
              ADD PERSONAL RESTRICTION
            </Typography>
          </DialogTitle>

          <Divider />

              <DialogContent>
                <Card style={{ border: 'none', boxShadow: 'none' }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <TextField
                            label={'Location Name'}
                            id={'location'}
                            name={'location'}
                            onChange={handleChange}
                            error={state.location === ''}
                            inputProps={{ style: { fontSize: '.85rem' } }}
                            value={state.location}
                            variant={'outlined'}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <TextField
                            label={'Category'}
                            id={'category'}
                            name={'category'}
                            onChange={handleChange}
                            error={state.category === ''}
                            inputProps={{ style: { fontSize: '.85rem' } }}
                            value={state.category}
                            variant={'outlined'}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <TextField
                            label={'Restriction Radius'}
                            id={'triggerRadius'}
                            name={'triggerRadius'}
                            type={'number'}
                            onChange={handleChange}
                            error={state.triggerRadius <= 0}
                            inputProps={{ style: { fontSize: '.85rem' } }}
                            InputProps={{ endAdornment: <InputAdornment position={'end'}> ft </InputAdornment> }}
                            value={state.triggerRadius}
                            variant={'outlined'}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <TextField
                            label={'Comments'}
                            id={'comments'}
                            name={'comments'}
                            multiline
                            onChange={handleChange}
                            error={state.comments === ''}
                            inputProps={{ style: { fontSize: '.85rem' } }}
                            value={state.comments}
                            variant={'outlined'}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <TextField
                            label={'Select a Type'}
                            id={'type'}
                            name={'type'}
                            onChange={handleChange}
                            select={true}
                            inputProps={{ style: { fontSize: '.85rem' } }}
                            SelectProps={{ native: true }}
                            value={state.type}
                            variant={'outlined'}
                          >
                            {typeCategories.map((option) => (
                              <option key={option.label} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>

                      {state.type === 'address' ? (
                        <Grid item xs={12}>
                          <FormControl fullWidth required>
                            <TextField
                              label={'Address'}
                              id={'address'}
                              name={'address'}
                              error={address === ''}
                              onChange={(event) => {
                                setAddress(event.target.value);
                              }}
                              inputProps={{ style: { fontSize: '.85rem' } }}
                              value={address}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <FormControl fullWidth required>
                              <TextField
                                label={'Latitude'}
                                id={'latitude'}
                                name={'latitude'}
                                onChange={(event) => {
                                  setLatitude(event.target.value);
                                }}
                                inputProps={{ style: { fontSize: '.85rem' } }}
                                value={latitude}
                                variant={'outlined'}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl fullWidth>

                              <TextField
                                label={'Longitude'}
                                id={'longitude'}
                                name={'longitude'}
                                onChange={(event) => {
                                  setLongitude(event.target.value);
                                }}
                                inputProps={{ style: { fontSize: '.85rem' } }}
                                value={longitude}
                                variant={'outlined'}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}


                    </Grid>


                  </CardContent>

                </Card>
              </DialogContent>

              <Divider />

              <DialogActions>
                <Button onClick={cancelDialog}>Cancel</Button>
                <Button type={'submit'} onClick={handleSave}>Add</Button>
              </DialogActions>


        </Dialog>
      </form>
    </div>
  );
};

export default AddPersonalRestrictionDialog;
