/* eslint-disable */
import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  FileText as FileTextIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  PieChart as PieChartIcon,
  Map as MapIcon,
  AlertTriangle as AlertIcon,
  User as UserIcon,
  Bell as NotificationIcon,
  MessageSquare as ChatIcon
} from 'react-feather';
import NavItem from './NavItem';
import NavItemNoHref from './NavItemNoHref';
import NavItemMobile from './NavItemMobile';
import { ParticipantContext } from '../../../context/ParticipantContext';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const user = {
  avatar: '/static/images/avatars/avatar01.png'
};

const generalViews = [
  {
    href: '/app/overview',
    icon: MapIcon,
    title: 'Overview'
  },
  {
    href: '/app/participantlist',
    icon: GroupOutlinedIcon,
    title: 'Participant List'
  },
  {
    href: '/app/globalschedule',
    icon: CalendarIcon,
    title: 'Global Schedule'
  },
  {
    href: '/app/usermanagement',
    icon: UserIcon,
    title: 'Account Management'
  },
  {
    href: '/app/notifications',
    icon: NotificationIcon,
    title: 'Notifications'
  },
  {
    href: '/app/chat',
    icon: ChatIcon,
    title: 'Chat'
  },
  {
    href: '/app/usage',
    icon: PieChartIcon,
    title: 'App Usage'
  }
];

const participantViews = [
  {
    href: '/app/participantstatus',
    icon: FileTextIcon,
    title: 'Participant Status'
  },
  {
    href: '/app/exclusionzone',
    icon: AlertIcon,
    title: 'Exclusion Zone'
  },
  {
    href: '/app/schedule',
    icon: CalendarIcon,
    title: 'Schedule'
  },
  {
    href: '/app/analytics',
    icon: BarChartIcon,
    title: 'Analytics'
  },
  {
    href: '/app/fivekey',
    icon: PieChartIcon,
    title: '5 Key Model'
  },
  {
    href: '/app/game',
    icon: SportsEsportsOutlinedIcon,
    title: 'Game and Tasks'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 48,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    width: 64,
    height: 64,
    margin: '0px 0px 5px 0px'
  },
  participantText: {
    margin: '0px 0px 10px 0px',
    width: '100%'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const participant = useContext(ParticipantContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  function NavBarItemsDesktop() {
    let navBarItemList = [];

    generalViews.map(item => {
      var props = {};
      props.key = item.title;
      props.title = item.title;

      if (item.href != null) {
        props.href = item.href;
      }

      if (item.icon != null) {
        props.icon = item.icon;
      }

      if (item.href != null) {
        navBarItemList.push(<NavItem {...props} />);
      } else {
        navBarItemList.push(<NavItemNoHref {...props} />);
      }
    });

    navBarItemList.push(<Divider />);

    participantViews.map(item => {
      var props = {};
      props.key = item.title;
      props.title = item.title;

      if (item.href != null) {
        props.href = item.href;
      }

      if (item.icon != null) {
        props.icon = item.icon;
      }

      if (item.href != null) {
        navBarItemList.push(<NavItem {...props} />);
      } else {
        navBarItemList.push(<NavItemNoHref {...props} />);
      }
    });

    return <div>{navBarItemList}</div>;
  }

  const contentDesktop = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Typography
          className={classes.participantText}
          color="textSecondary"
          variant="h5"
        >
          Participant
        </Typography>
        <Avatar className={classes.avatar} src={user.avatar} />
        <Typography className={classes.name} color="textSecondary" variant="h5">
          {participant.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <NavBarItemsDesktop />
      </Box>
      <Box flexGrow={1} />
      <Divider />
      <Typography style={{ paddingLeft: 5 }}>
        Version: {process.env.REACT_APP_DASHBOARD_VERSION}
      </Typography>
    </Box>
  );

  function NavBarItemsMobile() {
    let navBarItemList = [];

    generalViews.map(item => {
      var props = {};
      props.key = item.title;
      props.title = item.title;

      if (item.href != null) {
        props.href = item.href;
      }

      if (item.icon != null) {
        props.icon = item.icon;
      }

      navBarItemList.push(<NavItemMobile {...props} />);
    });

    participantViews.map(item => {
      var props = {};
      props.key = item.title;
      props.title = item.title;

      if (item.href != null) {
        props.href = item.href;
      }

      if (item.icon != null) {
        props.icon = item.icon;
      }

      navBarItemList.push(<NavItemMobile {...props} />);
    });

    return <div>{navBarItemList}</div>;
  }

  const contentMobile = (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxMobile}
    >
      <NavBarItemsMobile />
      <Box flexGrow={1} />
      <Divider />
      <Typography align={'right'} style={{ paddingRight: 5 }}>
        {process.env.REACT_APP_DASHBOARD_VERSION}
      </Typography>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          open
          variant="persistent"
        >
          {contentMobile}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {contentDesktop}
        </Drawer>
      </Hidden>
    </>
  );
};
NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
