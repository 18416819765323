import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import firebase from 'firebase/compat/app';

import './styles.css';

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  const firebaseConfig = {

    apiKey: "AIzaSyCDFh6ef8LkrktUxl4bSm_ViRMg9JVhrzs",

    authDomain: "nij-authentication-dev.firebaseapp.com",

    projectId: "nij-authentication-dev",

    storageBucket: "nij-authentication-dev.appspot.com",

    messagingSenderId: "374498573781",

    appId: "1:374498573781:web:dc514e5d62ec35a997515c",

    measurementId: "G-213K2C29HH"

  };



  firebase.initializeApp(firebaseConfig);
} else {
  const firebaseConfig = {
    apiKey: "AIzaSyC1A5cSaGy3_pfj9Mpd2Vw8eBmRl9d3ws4",
    authDomain: "nij-authentication.firebaseapp.com",
    projectId: "nij-authentication",
    storageBucket: "nij-authentication.appspot.com",
    messagingSenderId: "17504360767",
    appId: "1:17504360767:web:663eceabb9dd7724b5db8a",
    measurementId: "G-FFXJGTTMFS"
  };



  firebase.initializeApp(firebaseConfig);
}

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
