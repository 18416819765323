/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Polygon,
  Circle, Marker, Popup
} from 'react-leaflet';
import axios from 'axios';

import ConvertRestrictedLocation from '../../../utils/ConvertRestrictedLocation';

import { getPersonalRestrictions } from '../../exclusion/api';
import { ParticipantContext } from '../../../context/ParticipantContext';

import theme from 'src/theme';

const SpecificRestrictedZone = ({ setZoneGroup }) => {
  const participant = useContext(ParticipantContext);

  const pathOption = {
    color: theme.palette.zone,
    opacity: 0.5,
    fillOpacity: 0.6,
  };

  var individualRestrictedZoneGroup;
  useEffect(() => {
    individualRestrictedZoneGroup = [];
    if (participant != undefined) {
      getPersonalRestrictions(participant)
        .then((r) => {
          if (r.status === 200) {
            r.data.map((zone) => {
              if (zone.geom.type === "Point") {
                individualRestrictedZoneGroup.push(
                  <Marker position={zone.geom.coordinates}>
                    <Popup>
                      {zone.locationName}
                      <br />
                      Trigger Radius: {Math.round(zone.triggerDistance)} ft
                      <br />
                      Comments: {zone.comments}
                      <br />
                      {zone.geom.coordinates[0]},{zone.geom.coordinates[1]}
                      <br />
                      {zone.address}
                    </Popup>
                    <Circle
                      center={{ lat: zone.geom.coordinates[0], lng: zone.geom.coordinates[1] }}
                      radius={zone.triggerDistance / 3.28}
                    />
                  </Marker>
                )
              }
            });
            setZoneGroup(individualRestrictedZoneGroup);
          }
          else {
            setZoneGroup([]);
          }
        });
    }
  }, [participant.id]);

  return (
    <div>
      {individualRestrictedZoneGroup}
    </div>
  );
}

SpecificRestrictedZone.propTypes = {
  className: PropTypes.string
};

export default SpecificRestrictedZone;
