/* eslint-disable */
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, Routes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { ParticipantProvider } from './context/ParticipantContext';
import { NotificationProvider } from './context/NotificationContext';
import { TitleProvider } from './context/TitleContext';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import { useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


// Add a response interceptor
axios.interceptors.response.use(function(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  Sentry.setExtra('error', error);
  Sentry.captureException(error);
  return Promise.reject(error);
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3f53c74346254c82a222bf4b02349d24@o1242712.ingest.sentry.io/6406939',

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: 'nij-dashboard@' + process.env.REACT_APP_DASHBOARD_VERSION,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV

  });

}


const App = () => {
  const routing = useRoutes(routes);


  useEffect(() => {
    // Sentry.captureMessage("Heartbeat")
    const auth = getAuth();

    auth.onIdTokenChanged(async (user) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`;
    });
  });

  return (
      <ThemeProvider theme={theme}>
        <TitleProvider>
          <ParticipantProvider>
            <NotificationProvider>
              <GlobalStyles />
              {routing}
            </NotificationProvider>
          </ParticipantProvider>
        </TitleProvider>
      </ThemeProvider>
  );
};

export default App;
