/* eslint-disable */

import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
const lcpProxy = 'http://localhost:8010/proxy';

export const getAllMeetings = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/scheduling/meetings`, {});

    return data;

  } catch (error) {
    console.log(error);
  }
}
